<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      ref="formItem"
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses + ' xref_field_label'" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>
    <div @contextmenu.prevent="showContextMenu($event)">
      <treeselect
        ref="treeselect"
        v-model="localValue"
        :placeholder="placeholder || $locale.main.placeholder.select"
        class="custom_scrollbar xref_field"
        :options="computedOptions"
        :clearable="clearable"
        :load-options="loadOptions"
        :disabled="_isReadonly"
        :normalizer="normalizer"
        :clear-on-select="true"
        :clear-value-text="treeXrefField.clearValueText"
        :no-children-text="treeXrefField.noChildrenText"
        :loading-text="$locale.main.message.loading"
        :no-options-text="treeXrefField.noOptionsText"
        :no-results-text="treeXrefField.noResultsText"
        :match-keys="treeXrefField.matchKeys"
        :disable-branch-nodes="(!recursiveGroup || !!groupBy)"
        :async="true"
        :disable-immediate-search="true"
        :cache-options="false"
        :append-to-body="true"
        :searchable="true"
        :limit="3"
        @select="executeAutocomplete"
        @open="onMenuOpen"
        @search-change="onSearch"
      >
        <template slot="before-list">
          <div v-show="showSearchTip" class="vue-treeselect__tip vue-treeselect__seacrh-promt-tip">
            <div class="vue-treeselect__icon-container">
              <span class="vue-treeselect__icon-warning"/>
            </div>
            <span class="vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text">
              {{ treeXrefField.pressEnterToSearchText }}
            </span>
          </div>
        </template>

        <template slot="option-label" slot-scope="{ node, labelClassName, countClassName }">
          <label v-if="!node.raw.isLoadingLabel" :title="node.label" :class="labelClassName" style="color: #606266;">
            {{ node.label }}
            <span
              v-if="node.raw.children_count"
              :class="countClassName"
            >({{ node.raw.children_count }})</span>
          </label>

          <div v-else class="vue-treeselect__loading-tip">
            <div class="vue-treeselect__icon-container">
              <span class="vue-treeselect__icon-loader"></span>
            </div>
            <span class="vue-treeselect__tip-text vue-treeselect__loading-tip-text">
              {{ $locale.main.message.loading }}
            </span>
          </div>
          </template>
        </treeselect>
      </div>
        <vue-context ref="context-menu" class="el-dropdown-menu  el-dropdown-menu--small tab-menu" v-slot="{ data }">
          <div class="el-dropdown-menu__item" @click="copyLink(data)">
            <i class="el-icon-document-copy"></i>Копировать
          </div>
        </vue-context>
      </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import Vue from 'vue'

import mixin from '../mixins'
import registryMixin from './registry_mixins'
import InputLabel from '@/mixins/inputLabel'

// components
import Treeselect, { LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH } from '@bingosoftnn/vue-treeselect'
import '@bingosoftnn/vue-treeselect/dist/vue-treeselect.css'
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'

// utils
import FilterBuilder, { EComponentTypes } from '../utils'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { AddressAPI } from '@/services/AddressService/infrastructure/api/AddressAPI'

export default Vue.extend({
  name: 'xref_field',

  mixins: [mixin, registryMixin, InputLabel],

  components: {
    Treeselect,
    VueContext
  },

  inject: {
    getRegistryId: {
      default: () => () => null
    }
  },

  props: {
    registry_properties: {
      type: Array,
      frozen: true
    },
    label: {
      description: 'name',
      type: String
    },
    placeholder: {
      description: 'placeholder',
      type: String
    },
    tooltip: {
      description: 'tooltip',
      type: String
    },
    alias: {
      description: 'xref_alias',
      type: String,
      options: {
        tooltip: {
          show: true,
          content: `xref_address`
        }
      }
    },
    addressFieldAlias: {
      description: 'xref_addres_field',
      type: String,
      options: {
        removeSpaces: true,
        tooltip: {
          show: true,
          content: 'xref_addres_field_des'
        }
      }
    },
    addressFieldAliasGar: {
      description: 'xref_addres_field_gar',
      type: String,
      options: {
        removeSpaces: true,
        tooltip: {
          show: true,
          content: 'xref_addres_field_gar_des'
        }
      }
    },
    stateId: {
      description: 'xref_state',
      type: String
    },
    parameters: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'Параметры',
        showXrefOption: false,
        showEqualsTypes: true
      }
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    groupBy: {
      description: 'default_group_attr',
      type: String,
      options: {
        removeSpaces: true,
        tooltip: {
          show: true,
          content: 'xref_group'
        }
      }
    },
    groupOrderBy: {
      description: 'xref_sort',
      type: String,
      options: {
        removeSpaces: true
      }
    },
    recursiveGroup: {
      description: 'xref_recursive',
      type: Boolean
    },
    clearable: {
      type: Boolean,
      description: 'clearable',
      default: true
    },
    autocomplete: {
      type: Object,
      editor: 'XrefAutocomplete',
      description: 'xref_autocomplete',
      default () {
        return {
          // Определяет включено ли поведение
          is_active: false,

          // Определяет включено ли заполнение по кнопке
          button_only: false,

          // Мэппинг полей реестра с атрибутами карточки
          fields: []
        }
      }
    },
    applyRestrictions: {
      type: Boolean,
      description: 'apply_restrictions',
      default: false
    }
  },

  data () {
    return {
      treeXrefField: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noOptionsText: 'Нет данных',
        noResultsText: 'Не найдено',
        pressEnterToSearchText: 'Для поиска нажмите Enter'
      },
      isAllRecordLoaded: false,
      search: null,
      offset: 0,
      localValue: undefined,
      foundOptions: [],
      options: [],
      loaded: false,
      loading: false,
      searchLoading: false,
      searchLoaded: false,
      buttonPressed: false
    }
  },

  async mounted () {
    if (this.isEditor()) {
      return false
    }
    if (this.validationNeeded) {
      if (this.value === '[]' || !this.value) {
        this.$emit('input', this.localValue)
        this.$refs.formItem.form.validateField(this.name)
      }
    }
    this.parseValue()
    let elemtInput = this.$refs?.treeselect.$el.querySelector('.vue-treeselect__input')
    if (elemtInput) {
      elemtInput.setAttribute('enterkeyhint', 'enter')
    }
  },

  watch: {
    value: {
      async handler (value) {
        if (value !== this.localValue) {
          await this.parseValue()
          this.$emit('input', this.localValue || null)
          this.$set(this.getRawData(), this.name, this.getRawModel())
        }
      }
    },
    localValue: {
      handler () {
        this.$emit('input', this.localValue || null)
        this.$set(this.getRawData(), this.name, this.getRawModel())
      }
    },
    dataFilters: {
      async handler () {
        if (this.loaded) {
          this.offset = 0
          this.options = await this.loadData(true)
        }
      }
    },
    stateParams: {
      async handler () {
        if (this.loaded) {
          this.offset = 0
          this.options = await this.loadData(true)
        }
      }
    }
  },

  computed: {
    isAutocomplete () {
      if (this.autocomplete) {
        return this.autocomplete.is_active
      }

      return false
    },
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    },
    attributeId () {
      return parseInt(/attr_([0-9]+)_/i.exec(this.name) ? /attr_([0-9]+)_/i.exec(this.name)[1] : 0)
    },
    computedOptions () {
      if (this.loading) {
        return [...this.options, ...[{ id: -1, isDisabled: true, isLoadingLabel: true }]]
      } else if (this.searchLoaded) {
        return this.foundOptions
      } else {
        return this.options
      }
    },

    dataFilters () {
      if (!this.filters) return []
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.xrefField
      )

      return builder.buildAsRegistryService()
    },

    stateParams () {
      let params = []
      this.parameters.forEach((item) => {
        if (!item.type || item.type === 'field') {
          if (this.getModel()[item.attribute] && item.alias) {
            params.push({ name: item.alias, value: this.getModel()[item.attribute] })
          }
        } else if (item.type === 'constant' && item.alias) {
          params.push({ name: item.alias, value: item.attribute })
        } else if (item.type === 'current_user') {
          params.push({ name: item.alias, value: this.$store.getters['Authorization/userId'] })
        }
      })
      return params
    },

    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },

  methods: {
    copyLink () {
      const id = this.localValue
      if (!id) return
      const label = this.$refs.treeselect.getNode(id).label
      navigator.clipboard.writeText(label)
    },
    showContextMenu (event) {
      this.$refs['context-menu'].open(event)
    },
    getRawModel () {
      if (!this.localValue) {
        return null
      }

      let value = null
      const localId = this.localValue

      const deepFinder = (options) => {
        options.forEach((option) => {
          if (option.id === localId) {
            value = [{ 'id': localId, 'name': option.name }]
            throw new Error()
          }
          if ((option.children || []).length > 0) {
            deepFinder(option.children)
          }
        })
      }

      try {
        deepFinder(this.options)
      } catch (e) {}

      return value
    },
    async reloadById (recordId) {
      this.options = await this.loadData(true, null, recordId)
      this.loaded = false
    },
    async getAddress (id) {
      try {
        return (await APIClient.shared.request(new AddressAPI.GetAddresses({ id })))[0].address
      } catch (error) {
        console.error(`Ошибка получения адреса ФИАС addressId: ${id}`)
      }
      return null
    },
    async getAddressGARById (addressId) {
      try {
        return (await this.$http.get(`${this.$config.api}/garaddressservice/addresses?id=${addressId}`)).data[0].address
      } catch (error) {
        console.error(`Ошибка получения адреса ГАР addressId: ${addressId}`)
        return null
      }
    },
    executeAutocomplete (node, instanceId) {
      // Выполняет автозаполнение полей карточки
      // Вызывается при событии @input

      // Если автозаполнение настроено по кнопке, не заполняем поля
      if (this.autocomplete.button_only && !this.buttonPressed) {
        return
      }
      this.buttonPressed = false

      // Если автозаполнение не настроено или не настроен мэппинг полей для заполнения
      if (!this.isAutocomplete || !this.autocomplete.fields.length) {
        return
      }

      // Проверим, указан ли ID вн. записи, без него мы не получим данные и следовательно следуйщий алгоритм лишний
      if (!node.id) {
        return
      }

      // Получим инфу о вн. реестре
      if (this.attributeId) {
        this.$http.get(`${this.$config.api}/objecteditor/entities/${this.attributeId}/xref`).then((response) => {
          if (response.data.data) {
            const externalId = response.data.data.xref_object_id

            // Забираем данные из вн. реестра по указанному ID записи в xref_field (value)
            this.$http
              .get(`${this.$config.api}/registryservice/registry/${externalId}/data/${node.id}`)
              .then((record) => {
                if (record.data) {
                  try {
                    // Бежим по мэппингу полей с компонентами и заполняем поля ввода карточки
                    this.autocomplete.fields.forEach((field) => {
                      // Проверим ссылка или нет, если да - используем ссылочный псевдоним атрибута
                      const externalAttribute = field.isXref
                        ? `attr_${field.property}_id`
                        : field.isAddress
                          ? `attr_${field.property}_address`
                          : `attr_${field.property}_`

                      // attr_N_id для пр. ссылок равен значению null, либо числу (идентификатору записи)
                      // attr_N_id для мн. ссылок равен значению '{}', либо числовому массиву '{1, 2, 3}' как строка

                      let parsedValue = record.data[externalAttribute] || null

                      // Если ссылочное поле и тип данных string, значит это мн. ссылка с форматом "{1, 2, 3}" (тип массива PgSQL)
                      if (field.isXref && typeof parsedValue === 'string') {
                        parsedValue = JSON.parse(
                          parsedValue
                            .replace('{', '[')
                            .replace('}', ']')
                        )
                      }

                      this.getModel()[field.attribute] = parsedValue
                    })
                  } catch (e) {
                    // Есть вероятность, что JSON.parse выбросит исключение
                    console.error({
                      currentAttribute: field.attribute,
                      externalAttribute: field.property,
                      error: e
                    })
                  }
                }
              })
          }
        })
      }
    },
    async parseValue () {
      if (!this.value) {
        this.localValue = null
        return false
      }
      let parsed = null
      try {
        parsed = JSON.parse(this.value)
      } catch (e) {
      }
      if (parsed instanceof Array && parsed.length > 0) {
        if (!this.options.find((item) => {
          return item.id === parsed[0].id
        })) {
          // Если заполенено свойство addressFieldAlias (ФИАС)
          if (this.addressFieldAlias) {
            let addresId = null
            let isArrayAddress = false
            if (Array.isArray(parsed[0][this.addressFieldAlias])) {
              isArrayAddress = true
              addresId = parsed[0][this.addressFieldAlias][0]
            } else {
              addresId = parsed[0][this.addressFieldAlias]
            }
            if (addresId) {
              let address = await this.getAddress(addresId)
              if (isArrayAddress) {
                parsed[0][this.addressFieldAlias][0] = address
              } else {
                parsed[0][this.addressFieldAlias] = address
              }
            }
          }
          // Если заполенено свойство addressFieldAliasGar (ГАР)
          if (this.addressFieldAliasGar) {
            this.getAddressGAR({ parsed, addresAttr: this.addressFieldAliasGar })
          }

          this.options = this.options.concat(parsed)
        }
        this.localValue = parsed[0].id
      } else if (typeof parsed === 'number') {
        if (!this.options.find((item) => {
          return item.id === parsed
        })) {
          this.options = await this.loadData(true, null, parsed)
          this.loaded = false
        }
        this.localValue = parsed
      } else {
        this.localValue = null
      }
    },
    normalizer (node) {
      return {
        id: node.id || '',
        label: this.getNodeLabel(node)
      }
    },
    async getAddressGAR ({ parsed, addresAttr }) {
      let addresId = null
      let isArrayAddress = false
      if (Array.isArray(parsed[0][addresAttr])) {
        isArrayAddress = true
        addresId = parsed[0][addresAttr][0]
      } else {
        addresId = parsed[0][addresAttr]
      }
      if (addresId) {
        let address = await this.getAddressGARById(addresId)
        if (isArrayAddress) {
          parsed[0][addresAttr][0] = address
        } else {
          parsed[0][addresAttr] = address
        }
      }
    },
    getNodeLabel (node) {
      let name = (node.name || node.id)
      if (!this.alias) {
        return name
      }
      let label = this.alias
      label = label.replace(`{{name}}`, name)
      let attributes = this.alias.match(/\{{(.*?)\}}/g) || []
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        label = label.replace(`{{${attribute}}}`, node[attribute] ?? '')
      })

      return label
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        parentNode.children = await this.loadData(true, parentNode.id)
        callback()
      } else if (action === ASYNC_SEARCH) {
        this.searchLoaded = false
        this.searchLoading = true
        this.foundOptions = await this.loadData(true)
        callback(null, this.foundOptions)
        this.searchLoading = false
        this.searchLoaded = true
      }
    },
    onSearch (value) {
      this.search = value
      this.searchLoaded = false
      this.offset = 0
      this.foundOptions = []
    },
    async onMenuOpen () {
      this.options = await this.loadData()
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu()
        const magicNumber = 5
        menu.addEventListener('scroll', async () => {
          const hasReachedEnd = menu.scrollHeight - menu.scrollTop <= (menu.clientHeight + magicNumber)
          if (hasReachedEnd && !this.isAllRecordLoaded && !this.search && !this.groupBy && !this.loading) {
            this.offset += 100
            this.options = await this.loadData(true)
          }
        })
      })
    },
    getParameters (parentNode = null, valueId) {
      let answer = []
      if (this.search) {
        let valueByInput = this.$refs.treeselect.$el.querySelector('.vue-treeselect__input').value
        answer.push(`search=${encodeURIComponent(valueByInput)}`)
        answer.push(`limit=50`)
      } else {
        if (valueId) {
          answer.push(`filter[${this.dataFilters.length}]=id,eq,${valueId}`)
        } else {
          if (this.groupBy) {
            answer.push(`group_by=${this.groupBy}`)
            if (this.groupOrderBy) {
              answer.push(`order_by=${this.groupOrderBy}`)
            }
            if (parentNode) {
              answer.push(`node=${parentNode}`)
            }
            if (this.recursiveGroup) {
              answer.push(`recursive=1`)
            }
          } else {
            answer.push(`offset=${this.offset}`)
            answer.push(`limit=100`)
          }
        }
      }
      if (this.stateId) {
        answer.push(`state_id=${this.stateId}`)
      }
      this.stateParams.forEach((param) => {
        answer.push(`parameters[${param.name}]=${param.value}`)
      })
      this.dataFilters.forEach((filter, index) => {
        answer.push(`filter[${index}]=${filter}`)
      })
      if (this.applyRestrictions) {
        answer.push(`apply_restrictions=1`)
      }
      return answer
    },
    async loadData (force = false, parentNode = null, valueId = null) {
      if (!this.loaded || force) {
        this.loading = true
        if (this.attributeId) {
          let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${this.attributeId}/data?${this.getParameters(parentNode, valueId).join('&')}`)
            .catch(() => {
              this.loading = false
            })
          this.loading = false
          this.loaded = true
          if (this.offset > 0) {
            this.isAllRecordLoaded = response.data.length === 0
            return [...this.options, ...response.data]
          } else {
            if (this.groupBy) {
              return (response.data || []).map((item) => {
                item.children = (item.children_count > 0 || (!this.recursiveGroup && !parentNode && !this.search)) ? null : false
                return item
              })
            } else {
              return response.data
            }
          }
        }
      }
      return this.options
    }
  }
})
</script>

<style>
/* Отступ из-за vue-treeselect @TODO vue-treeselect убрать побочный эффект влияния на положение компонента в контейнере */
.xref_field_label {
    position: relative;
    top: 13px;
}
/* Отступ из-за vue-treeselect @TODO vue-treeselect убрать побочный эффект влияния на положение компонента в контейнере */
.xref_field.vue-treeselect {
    display: inline-block;
    top: 16px;
}
.xref_field .vue-treeselect__control {
    height: 40px;
}

.xref_field .vue-treeselect__single-value {
    color: #606266;
}

.xref_field .vue-treeselect__input {
    color: #606266;
    vertical-align: baseline;
}

.xref_field .vue-treeselect__placeholder {
    line-height: 40px;
}

.xref_field .vue-treeselect__value-container {
    line-height: 20px;
}
.vue-treeselect__portal-target {
    z-index: 10000 !important;
}
.el-form-item.is-error .xref_field {
  border: 1px solid red;
  border-radius: 6px;
}
.xref_field.vue-treeselect--disabled .vue-treeselect__control {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    cursor: not-allowed;
}
.xref_field.vue-treeselect--disabled .vue-treeselect__single-value {
    color: #C0C4CC;
}
</style>
